import React from 'react';

import { tournamentName, eventName, eventDate } from '../utils/tournamentName';

import './SelectedEvent.css';

import Date from './input/Date';
import Quality from './input/Quality';
import SerieMgmt from './input/SerieMgmt';
import EventState from './input/EventState';
import EventOperations from './input/EventOperations';

const SelectedEvent = props => {
    const selected = props.selected;
    const series = props.series;
    const eventChangeHandler = props.eventChangeHandler;

    if( !selected ) return '';

    // add myKey because Text keeps state
    return (
        <div>
            <div className='SelectedEvent-header'>{tournamentName(selected)} ({eventName(selected)})</div>
            <EventOperations header='Event Operations'
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} />
            <Date header='Event Date'
                defaultValue={eventDate(selected)}
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} />
            <Quality header='Quality'
                defaultValue={selected.qualityText}
                quality={selected.quality}
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} />
            <SerieMgmt header='Series'
                series={series}
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} />
            <EventState header='Event Data'
                eventStatus={selected.status}
                isTeam={selected.isTeam}
                submitHandler={eventChangeHandler}
                myKey={selected.eventPk} />

        </div>
    );
}

export default SelectedEvent;
import React, { useState } from 'react';
import ReactGA from "react-ga4";

import { eventName } from '../../utils/tournamentName';
import './TournamentControl.css';

import NoData from '../../dataStatus/NoData';
import TournamentDisplay from './TournamentDisplay';
import FencerDisplay from './FencerDisplay';

const NO_CHOICE = 0;

const TournamentControl = props => {
    const [selection, setSelection] = useState(NO_CHOICE);
    const [selectedFencer, setSelectedFencer] = useState(null);

    const tournament = props.tournament;
    const selectionMade = props.selectionMade;
    const chooseContentHandler = props.chooseContentHandler;

    const eventSelectedHandler = selectedEvent => {
        chooseContentHandler();
        setSelectedFencer(null);
        setSelection(selectedEvent);
        ReactGA.event( 'select_content', {
            content_type: 'event_link',
            item_id: selectedEvent.eventPk.toString()
        });
    }

    const selectedFencerHandler = fencer => {
        setSelectedFencer(fencer);
    }

    const isAccessible = event => event.status === 'withData' && event.quality === 'ok';

    switch (tournament.events.length) {
        case 0:
            return (
                <TournamentDisplay name={tournament.name}>
                    <NoData/>
                </TournamentDisplay>
            );

        case 1:
            const event = tournament.events[0];
            if( isAccessible(event) )
                return (
                    <TournamentDisplay name={tournament.name}>
                        <div className='TournamentControl-label'>{eventName(event)}</div>
                        <FencerDisplay event={event}
                                        otherEvents={[]}
                                        setEventHandler={ () => {} }
                                        selectedFencer={selectedFencer}
                                        selectedFencerHandler={selectedFencerHandler} />
                    </TournamentDisplay>
                );
            else
                return (
                    <TournamentDisplay name={tournament.name}>
                        <div className='TournamentControl-label'>{eventName(event)}</div>
                        <NoData/>
                    </TournamentDisplay>
                );

        default:
            if( ! selectionMade ) {
                const events = tournament.events.map( e => {
                    if( isAccessible(e) )
                        return (
                            <div key={e.eventPk} className='TournamentControl-line' onClick={ () => eventSelectedHandler(e) }>
                                <span className='TournamentControl'>{eventName(e)}</span>
                            </div>
                        );
                    else 
                        return (<div key={e.eventPk} className='TournamentControl-line'>{eventName(e)}</div>);
                });

                return (
                    <TournamentDisplay name={tournament.name}>
                        {events}
                        <br/>
                    </TournamentDisplay>
                );
            } else {
                const fencerDisplay = <FencerDisplay event={selection}
                                                    otherEvents={[]}
                                                    setEventHandler={ () => {} }
                                                    selectedFencer={selectedFencer}
                                                    selectedFencerHandler={selectedFencerHandler} />

                return (
                    <TournamentDisplay name={tournament.name}>
                        <div className='TournamentControl-label'>{eventName(selection)}</div>
                        {fencerDisplay}
                    </TournamentDisplay>
                );
            }
    }
};

export default TournamentControl;
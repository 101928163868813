import React, { useState } from 'react';

import Ok from './Ok';
import './EventState.css';

const KEEP = 'keep';
const APPROVE = 'approve';
const DISABLE = 'disable';
const FETCH = 'fetch';

const descriptions = {};
descriptions[KEEP] = '---';
descriptions[APPROVE] = 'Approve';
descriptions[DISABLE] = 'Unapprove';
descriptions[FETCH] = 'Fetch Content';

const EventState = props => {
    const header = props.header;
    const eventStatus = props.eventStatus;
    const isTeam = props.isTeam;
    const submitHandler = props.submitHandler;
    const key = props.myKey;

    const [eventStatusValue, setEventStatusValue] = useState(KEEP);
    const [isTeamValue, setIsTeamValue] = useState(isTeam);
    const [keyValue, setKeyValue] = useState(key);

    if( key !== keyValue ) {
        setKeyValue(key);
        setEventStatusValue(KEEP);
        setIsTeamValue(isTeam);
    }

    const clickHandler = () => {
        setEventStatusValue(KEEP);
        submitHandler('eventAction', {eventAction: eventStatusValue, isTeam: isTeamValue});
    }

    const changeStatusHandler = event => {
        setEventStatusValue(event.target.value);
    };

    const changeIsTeamHandler = () => {
        setIsTeamValue(!isTeamValue);
    }

    const options = [];
    options.push(KEEP);
    if( eventStatus === 'unapproved' ) {
        options.push(APPROVE);
    } else if( eventStatus === 'ready' ) {
        options.push(DISABLE);
        options.push(FETCH);
    } else if( eventStatus === 'downloading' ) {
        options.push(DISABLE);
    } else if( eventStatus === 'error' ) {
        options.push(DISABLE);
    } else if( eventStatus === 'withData' ) {
        options.push(DISABLE);
    }

    return (
        <div className='EventState'>
            {header}:
            <select className='EventState' value={eventStatusValue} onChange={changeStatusHandler}>
                { options.map( o => <option key={o} value={o}>{descriptions[o]}</option> ) }
            </select>
            { eventStatusValue === APPROVE ? 
                <span>
                    Is Team:
                    <input className='EventState' type='checkbox' checked={isTeamValue} onChange={changeIsTeamHandler} />
                </span> : '' }
            { eventStatusValue === KEEP ? '' : <Ok clickHandler={clickHandler} /> }
        </div>
    );
}

export default EventState;